.work {
  padding: 0 3rem 0;

  @include xl {
    padding: 0 1rem 0;
  }

  &-help {
    display: block;
    margin-top: 1rem;
    margin-right: 15rem;
    opacity: 0.5;

    @include xl {
      margin-right: 5rem;
    }

    @include lg {
      display: none;
    }
  }
}

.slide {
  &-title {
    font-family: "NeueMontreal-Regular";
    font-size: 9rem;
    letter-spacing: 0.05em;
    color: $secondary-color;
    left: 3rem;
    top: 18rem;
    position: absolute;
    z-index: 1000;
    width: 60rem;
    pointer-events: none;

    @include xl {
      font-size: 5rem;
      width: 80%;
    }

    @include sm {
      font-size: 2.5rem;
      width: 90%;
      left: 2rem;
      top: 28rem;
    }
  }

  &-id {
    font-family: "Barracuda-Bold";
    font-size: 1.5rem;
    letter-spacing: 0.05em;
    color: $secondary-color;
    display: flex;
    right: 18rem;
    top: 10rem;
    position: absolute;
    z-index: 1000;
  }

  &-image {
    position: relative;
    height: 30rem;
    width: 25rem;
    transform: translate(-50%, -50%);
    -webkit-transition-duration: 100ms;
    transition-duration: 400ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    will-change: width, height, transform;
    object-fit: cover;
    filter: grayscale(100%) sepia(0) brightness(80%);
    top: 20rem;
    left: 50%;
    cursor: pointer;

    @include xl {
      height: 45rem;
      width: 35rem;
    }

    @include lg {
      height: 45rem;
      width: 20rem;
    }

    @include sm {
      height: 25rem;
      width: 15rem;
    }

    &:hover {
      width: 35rem;
      height: 40rem;

      @include xl {
        height: 50rem;
        width: 40rem;
      }

      @include sm {
        height: 35rem;
        width: 20rem;
      }
    }
  }
}
.splide {
  overflow: inherit;
  height: 80vh;
}
.splide__track {
  overflow: visible;
}

.splide__arrows {
  position: absolute;
  display: flex;
  right: 20rem;
  top: 23rem;
}

.splide__arrow {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  @include xl {
    display: none;
  }

  & svg {
    fill: #f0f0f2;
    height: 2em;
    width: 2em;

    &:hover {
      fill: rgba(255, 77, 0);
    }
  }
}

.splide- .splide__arrow--prev {
  right: 6rem;
  left: 0;
}

.splide__arrow--next {
  left: 6rem;
  right: 0;
}

.splide__pagination {
  display: none;
}
