@import "../../../base.scss";

.logo-navbar {
  font-family: "Barracuda-Bold";
  font-size: 1.5rem;
  text-decoration: none;
  z-index: 102;
  position: relative;
  background: linear-gradient(
    to right,
    rgba(255, 77, 0),
    rgba(255, 77, 0) 50%,
    rgba(240, 240, 242) 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 4s ease;

  &:hover {
    background-position: 0 100%;
  }

  &.switch {
    background: linear-gradient(
      to right,
      rgba(13, 13, 13),
      rgba(13, 13, 13) 50%,
      rgba(13, 13, 13) 50%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 100ms ease;
  }
}

.text-menu-elements {
  font-family: "NeueMontreal-Regular";
  font-size: 1.25rem;
  text-decoration: none;
  padding: 0 1rem;
  position: relative;
  display: inline-block;
  background: linear-gradient(
    to right,
    rgba(255, 77, 0),
    rgba(255, 77, 0) 50%,
    rgba(240, 240, 242) 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 800ms ease;

  @include xl {
    font-size: 2.5rem;
    background: linear-gradient(
      to right,
      rgba(13, 13, 13),
      rgba(13, 13, 13) 50%,
      rgba(13, 13, 13) 50%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 100ms ease;
    margin-bottom: 1.5rem;
  }

  &.active::before {
    content: "";
    border-bottom: 2px solid $secondary-color;
    width: 80%;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include xl {
      width: 90%;
      border-bottom: 3px solid $primary-color;
    }
  }

  &:hover {
    background-position: 0 100%;
  }
}

.navbar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  //background-color: red;
  padding: 1rem 3rem;
  list-style: none;

  @include xl {
    align-items: center;
    padding: 0.5rem 1rem;
  }

  &-menu {
    display: flex;
    list-style: none;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    padding-top: 10rem;
    top: 0;
    left: -100%;
    opacity: 1;

    &.active {
      background: $secondary-color;
      left: 0;
      opacity: 1;
      //transition: all 0.6s ease;
      z-index: 1;
    }

    @media (min-width: 1060px) {
      justify-content: flex-end;
      text-align: center;
      padding-right: 7rem;
      left: 0;
      flex-direction: row;
      padding-top: 0;
      top: 50%;
      transform: translate(0, -50%);
      height: auto;
    }
  }
}

.hamburger-react {
  display: none;

  @include xl {
    z-index: 102;
    color: $secondary-color;
    display: flex;
    cursor: pointer;
  }
}
