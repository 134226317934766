@font-face {
  font-family: "Barracuda-Bold";
  src: local("Barracuda-Bold"),
    url("./fonts/Barracuda-Bold.woff2") format("truetype");
}

@font-face {
  font-family: "Barracuda-BoldItalic";
  src: local("Barracuda-BoldItalic"),
    url("./fonts/Barracuda-BoldItalic.woff2") format("truetype");
}

@font-face {
  font-family: "Barracuda-Italic";
  src: local("Barracuda-Italic"),
    url("./fonts/Barracuda-Italic.woff2") format("truetype");
}

@font-face {
  font-family: "Barracuda-Light";
  src: local("Barracuda-Light"),
    url("./fonts/Barracuda-Light.woff2") format("truetype");
}

@font-face {
  font-family: "Barracuda-LightItalic";
  src: local("Barracuda-LightItalic"),
    url("./fonts/Barracuda-LightItalic.woff2") format("truetype");
}

@font-face {
  font-family: "Barracuda-Regular";
  src: local("Barracuda-Regular"),
    url("./fonts/Barracuda-regular.woff2") format("truetype");
}

@font-face {
  font-family: "NeueMontreal-Bold";
  src: local("NeueMontreal-Bold"),
    url("./fonts/NeueMontreal-Bold.woff2") format("truetype");
}

@font-face {
  font-family: "NeueMontreal-BoldItalic";
  src: local("NeueMontreal-BoldItalic"),
    url("./fonts/NeueMontreal-BoldItalic.woff2") format("truetype");
}

@font-face {
  font-family: "NeueMontreal-Italic";
  src: local("NeueMontreal-Italic"),
    url("./fonts/NeueMontreal-Italic.woff2") format("truetype");
}

@font-face {
  font-family: "NeueMontreal-Light";
  src: local("NeueMontreal-Light"),
    url("./fonts/NeueMontreal-Light.woff2") format("truetype");
}

@font-face {
  font-family: "NeueMontreal-LightItalic";
  src: local("NeueMontreal-LightItalic"),
    url("./fonts/NeueMontreal-LightItalic.woff2") format("truetype");
}

@font-face {
  font-family: "NeueMontreal-Regular";
  src: local("NeueMontreal-Regular"),
    url("./fonts/NeueMontreal-Regular.woff2") format("truetype");
}

@font-face {
  font-family: "Sykes-Regular";
  src: local("Sykes-Regular"),
    url("./fonts/Sykes-Regular.woff2") format("truetype");
}

@font-face {
  font-family: "HelveticaNow-Bold";
  src: local("HelveticaNow-Bold"),
    url("./fonts/HelveticaNowDisplay-Bold.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "HelveticaNow-Medium";
  src: local("HelveticaNow-Medium"),
    url("./fonts/HelveticaNowDisplay-Medium.otf") format("truetype");
  font-weight: medium;
}

@font-face {
  font-family: "HelveticaNow-Regular";
  src: local("HelveticaNow-Regular"),
    url("./fonts/HelveticaNowDisplay-Regular.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Cakra";
  src: local("Cakra"), url("./fonts/Cakra-Normal.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "PPFraktionMono-Regular";
  src: local("PPFraktionMono-Regular"),
    url("./fonts/PPFraktionMono-Regular.otf") format("truetype");
  font-weight: normal;
}
