.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}

.samples-masonry {
  padding: 0 3rem;
  margin-top: 1rem;
  width: 100%;
  column-count: 4;
  column-gap: 0.5rem;

  @include xl {
    column-count: 2;
  }

  @include sm {
    padding: 0 1rem;
    column-count: 1;
  }

  .brick {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;

    @include sm {
      margin-bottom: 1rem;
    }

    img,
    video {
      max-width: 100%;
      vertical-align: middle;
    }

    &:hover .overlay {
      opacity: 1;
      //cursor: pointer;
    }
  }
}
