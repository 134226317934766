.sykes {
  &-intro {
    padding: 2rem 3rem 0;

    @include xl {
      padding: 2rem 1rem 0;
    }
  }

  .banner {
    width: 100%;
    position: absolute;
    z-index: -1;
  }

  &-project {
    padding: 2rem 3rem 0;

    @include xl {
      padding: 2rem 1rem 0;
    }
  }
  .styles-soon {
    margin-top: 30rem;

    @include sm {
      margin-top: 15rem;
    }
  }

  .posters-wrapper {
    margin-top: 22rem;
    margin-bottom: 12.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include sm {
      margin-top: 12rem;
    }

    .poster-1 {
      width: 110%;
    }
    .poster-2 {
      float: right;
      width: 85%;
    }
  }

  .char-wrapper {
    max-width: 100%;
    display: grid;
    grid-gap: 3rem 1rem;
    grid-template-columns: repeat(15, 1fr);

    @include md {
      grid-template-columns: repeat(9, 1fr);
    }

    @include sm {
      grid-template-columns: repeat(6, 1fr);
    }

    .char-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .sykes-video {
    display: block;
    width: 50%;
    margin: 12.5rem auto;

    @include md {
      width: 100%;
    }
  }

  .more {
    position: relative;
    height: 100vh;
  }

  section {
    overflow: inherit;
    position: sticky;
    width: 100%;

    @include md {
      overflow: hidden;
    }

    .scroll-sykes {
      width: 100%;
      display: flex;

      div {
        font-family: "Sykes-Regular";
        font-size: 600px;
        // letter-spacing: -0.05em;
        color: $secondary-color;
        white-space: nowrap;
        animation: animate 60s linear infinite;
        animation-delay: -60s;
      }

      div:nth-child(2) {
        animation: animate2 60s linear infinite;
        animation-delay: -30s;
      }
    }

    @keyframes animate {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }

    @keyframes animate2 {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-200%);
      }
    }
  }
}
