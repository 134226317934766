@import "./fonts.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --bodyColor: "#0d0d0d";
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: var(--bodyColor);
  -webkit-user-select: none;
  user-select: none;
}

a {
  all: unset;
}

$primary-color: #0d0d0d;
$secondary-color: #f0f0f2;
$third-color: #767676;

$primary-color24: #111111;
$secondary-color24: #f8f8f8;
$third-color24: #d6d6d6;

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.intro-title {
  font-family: "Barracuda-Bold";
  font-size: 4.5rem;
  line-height: 4.875rem;
  letter-spacing: 0.05em;
  color: $secondary-color;
}

.intro-subtitle {
  font-family: "Barracuda-Bold";
  font-size: 2.5rem;
  line-height: 2.875rem;
  letter-spacing: 0.05em;
  color: $secondary-color;
}

.text-about {
  font-family: "NeueMontreal-Regular";
  font-size: 5rem;
  line-height: 5.25rem;
  letter-spacing: 0;
  color: $secondary-color;

  @include xl {
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: 0.03em;
  }

  @include sm {
    font-size: 32px;
    line-height: 34px;
    letter-spacing: 0.01em;
  }
}

.text-post-samples {
  font-family: "Barracuda-Regular";
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0.05em;
  color: $secondary-color;
}

.text-see {
  font-family: "NeueMontreal-Regular";
  font-size: 2.5rem;
  line-height: 3rem;
  letter-spacing: 0.05em;
  text-decoration: none;
  background: linear-gradient(
    to right,
    rgba(255, 77, 0),
    rgba(255, 77, 0) 50%,
    rgba(240, 240, 242) 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 800ms ease;

  &:hover {
    background-position: 0 100%;
    font-weight: 1000;
  }
}

.text-hold {
  font-family: "NeueMontreal-Regular";
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  color: $third-color;
  text-align: right;
}

.text-social {
  font-family: "Barracuda-Light";
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.05rem;

  background: linear-gradient(
    to right,
    rgba(255, 77, 0),
    rgba(255, 77, 0) 50%,
    rgba(240, 240, 242) 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 600ms ease;

  &:hover {
    background-position: 0 100%;
  }
}

.sykes-text-title {
  margin-top: 11.5rem;
  font-family: "Sykes-Regular";
  font-size: 12.5rem;
  letter-spacing: -0.05em;
  color: $secondary-color;
  padding-bottom: 11.5rem;
  border-bottom: 1px solid $secondary-color;

  @include sm {
    margin-top: 3rem;
    font-size: 7rem;
  }
}

.sykes-text-600 {
  margin-top: 1rem;
  font-family: "NeueMontreal-Regular";
  font-size: 1rem;
  letter-spacing: 0.05em;
  color: $secondary-color;
}

.sykes-text-phrase {
  margin-top: 20rem;
  font-family: "Sykes-Regular";
  font-size: 7.5rem;
  letter-spacing: -0.05em;
  color: $secondary-color;
  width: 80%;

  @include sm {
    font-size: 3.5rem;
    width: 100%;
  }
}

.sykes-text-author {
  margin-top: 1rem;
  font-family: "NeueMontreal-Regular";
  font-size: 1.75rem;
  letter-spacing: 0.05em;
  color: $secondary-color;
  width: 23rem;
  position: absolute;
  right: 5rem;

  @include sm {
    font-size: 1rem;
    width: 100%;
    width: 15;
    right: 0;
  }
}

.sykes-text-characteres {
  padding-top: 1rem;
  margin-bottom: 6rem;
  font-family: "NeueMontreal-Regular";
  font-size: 1.75rem;
  color: $secondary-color;
  width: 50%;
  letter-spacing: 0.05em;
  border-top: 1px solid $secondary-color;

  @include md {
    width: 100%;
  }
}

.sykes-text-more {
  font-family: "Sykes-Regular";
  font-size: 8rem;
  letter-spacing: -0.05em;
  color: $secondary-color;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.logo_new {
  fill: $primary-color24;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 999;
  gap: 2.5rem;

  .loader {
    text-align: center;
  }

  &__image {
    width: 2.5rem;
  }
}
