.queimadasfitas {
  padding-bottom: 25rem;

  @include xl {
    padding-bottom: 15rem;
  }

  &-intro {
    padding: 2rem 3rem 0;

    @include xl {
      padding: 2rem 1rem 0;
    }
  }

  &-project-1 {
    padding-top: 2rem;
    padding-bottom: 15rem;
    display: flex;
    justify-content: center;
    pointer-events: none;

    @include sm {
      padding-bottom: 5rem;
    }

    .logos {
      width: 35rem;

      @include md {
        width: 25rem;
      }
    }

    &-vol {
      position: absolute;
      margin-top: 30rem;
      left: 60%;

      @include md {
        margin-top: 20rem;
        left: 70%;
      }
    }

    .simbolo-1 {
      display: block;
      position: absolute;
      z-index: 2;
      width: 4rem;

      @include lg {
        display: none;
      }
    }

    .simbolo-2 {
      display: block;
      position: absolute;
      z-index: 2;
      left: 0;
      margin-top: 25rem;
      width: 22rem;

      @include lg {
        display: none;
      }
    }

    .simbolo-3 {
      display: block;
      position: absolute;
      z-index: 2;
      right: 5rem;
      margin-top: 20rem;
      width: 11rem;

      @include lg {
        display: none;
      }
    }
  }

  &-project-2 {
    padding: 2rem 3rem 0;

    @include xl {
      padding: 2rem 1rem 0;
    }

    .parallax {
      height: 600px;

      @include sm {
        height: 300px;
      }
    }

    .logos-wrapper {
      max-width: 100%;
      display: grid;
      margin-top: 10rem;
      grid-gap: 1rem;
      grid-template-columns: repeat(3, 1fr);

      @include md {
        grid-template-columns: repeat(2, 1fr);
      }

      @include sm {
        margin-top: 5rem;
      }

      .logo {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .simbols {
    width: -webkit-fill-available;
    margin-top: 17rem;
    margin-bottom: 17rem;
    pointer-events: none;

    @include lg {
      margin-top: 7rem;
      margin-bottom: 7rem;
    }

    @include sm {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }
  }

  .grid {
    width: 100%;
    margin-top: 10rem;
    pointer-events: none;

    @include lg {
      margin-top: 7rem;
    }

    @include sm {
      margin-top: 0;
    }
  }

  &-project-3 {
    padding: 0 3rem 0;
    pointer-events: none;

    @include xl {
      padding: 0 1rem 0;
    }

    .pubs-wrapper {
      margin-top: 10rem;
      max-width: 100%;
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(4, 1fr);

      @include md {
        grid-template-columns: repeat(2, 1fr);
      }

      @include sm {
        margin-top: 7rem;
      }

      .pubs-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
