.volumetricworld {
  padding: 2rem 3rem 15rem;

  @include xl {
    padding: 2rem 1rem 10rem;
  }

  &-banner {
    margin-top: 2rem;
    width: -webkit-fill-available;
  }

  &-last {
    margin-top: 4rem;
    width: -webkit-fill-available;
  }
}

.volumetric-masonry {
  margin-top: 0.5rem;
  width: 100%;
  column-count: 2;
  column-gap: 0.5rem;
  @include sm {
    margin-bottom: 1rem;
  }

  @include sm {
    padding: 0 1rem;
    column-count: 1;
  }

  .brick {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;

    @include sm {
      margin-bottom: 1rem;
    }

    img {
      max-width: 100%;
      vertical-align: middle;
    }

    &:hover .overlay {
      opacity: 1;
      cursor: pointer;
    }
  }
}
