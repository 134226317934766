// .ring {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 22px;
//   height: 22px;
//   border: 2px solid rgba(255, 255, 255, 0.808);
//   border-radius: 100%;
//   transform: translate(-50%, -50%);
//   -webkit-transition-duration: 100ms;
//   transition-duration: 100ms;
//   -webkit-transition-timing-function: ease-out;
//   transition-timing-function: ease-out;
//   will-change: width, height, transform, border;
//   z-index: 999;
//   pointer-events: none;

//   @include xl {
//     border: none;
//   }
// }

.dot {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 22px;
  border: 2px solid #d6d6d6;
  mix-blend-mode: difference;
  border-radius: 100%;
  background: none;
  transform: translate(-50%, -50%);
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  will-change: width, height, transform, border, background;
  z-index: 999;
  pointer-events: none;

  @include xl {
    border: none;
  }
}

// .ring.hovered {
//   width: 50px;
//   height: 50px;
//   border-width: 3px;
//   border-color: lightgray;
// }

.dot.hovered-nav-items {
  width: 100px;
  height: 100px;
  border-width: 3px;
  border-color: rgba(255, 77, 0);

  @include xl {
    border: none;
  }
}

.dot.hovered-logo {
  width: 200px;
  height: 200px;
  border-width: 4px;
  border-color: rgba(255, 77, 0);

  @include xl {
    border: none;
  }
}

.dot.hovered-social {
  width: 140px;
  height: 140px;
  border-width: 4px;
  border-color: rgba(255, 77, 0);
  mix-blend-mode: hard-light;
}

.dot.hovered-projects-images {
  width: 300px;
  height: 300px;
  border: none;
  background: rgba(255, 77, 0);
  mix-blend-mode: hard-light;

  @include xl {
    background: none;
  }
}

.dot.hovered-see {
  width: 300px;
  height: 300px;
  border-width: 4px;
  border-color: rgba(255, 77, 0);

  @include xl {
    border: none;
  }
}

.dot.hovered-sykes-char {
  width: 100px;
  height: 100px;
  border-width: 3px;
  border-color: rgba(255, 00, 0);

  @include xl {
    border: none;
  }
}
