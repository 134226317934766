.altabaixa {
  padding: 2rem 3rem 0;

  @include xl {
    padding: 2rem 1rem 0;
  }

  @include sm {
    padding-bottom: 15rem;
  }

  &-project-intro {
    margin-top: 2rem;
    display: flex;
    justify-content: center;

    .logos {
      margin: 0 auto;
      width: 100%;
    }
  }

  .parallax {
    height: 600px;

    @include sm {
      height: 300px;
    }
  }

  .pages-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .pages {
      width: 100%;
    }
  }
}

.nv-altabaixa {
  padding: 6.5rem 1rem 0;
}
