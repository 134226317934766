.about {
  padding: 2rem 3rem 15rem;

  @include xl {
    padding: 2rem 1rem 15rem;
  }

  .social {
    margin: 10rem 0 0;

    @include xl {
      margin: 7rem 0 0;
    }

    &-area {
      list-style: none;
      display: flex;
      justify-content: left;

      @include md {
        display: block;
      }

      &-elements {
        cursor: pointer;
        padding: 1rem 1rem 1rem 0;
        margin: 1rem 2rem 1rem 0;

        &-text {
          text-decoration: none;
        }
      }
    }
  }
}
