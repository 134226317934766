.cultureinn {
  padding: 2rem 3rem 0;

  @include xl {
    padding: 2rem 1rem 0;
  }

  @include sm {
    padding-bottom: 15rem;
  }

  section {
    position: fixed;
    width: 100%;
    bottom: 0;

    @include sm {
      position: relative;
    }
  }

  &-list {
    text-align: center;
  }
  &-list-element {
    width: 12.5rem;
    margin: 5rem auto 0;
    position: relative;
  }
}

.scroll {
  width: 100%;
  display: flex;
  transform: rotate(-10deg);

  div {
    font-family: "NeueMontreal-Regular";
    font-size: 12.5rem;
    color: $secondary-color;
    white-space: nowrap;
    text-transform: uppercase;
    animation: animate 20s linear infinite;
    animation-delay: -20s;

    @include sm {
      font-size: 8rem;
    }
  }

  div:nth-child(2) {
    animation: animate2 20s linear infinite;
    animation-delay: -10s;
  }
}

@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}
