.landing-page {
  overflow: hidden;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  display: block;
}
.paper-btn {
  display: block;
}

.top-page-banner {
  width: 100%;
  height: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgb(17, 17, 17, 0.07);
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }
  &__emoji {
    font-family: "Cakra";
    font-size: 1rem;
    color: $primary-color;
  }

  &__text {
    white-space: nowrap;
    font-family: "HelveticaNow-Regular";
    font-size: 0.875rem;
    color: $primary-color;

    span {
      font-family: "HelveticaNow-Bold";
    }
  }
}

.top-page-content {
  margin: 1.5rem 1rem 0 1rem;
  position: relative;
}

.page-content-parent {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.page-content {
  margin: 7rem 1rem 0 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.top-page-content__bottom-page {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.new-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  z-index: 5;
  margin: 1.5rem 1rem 0;
  width: -webkit-fill-available;

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: flex-end;
  }
}

.logo-new {
  height: 2rem;
  fill: $primary-color24;

  &--white {
    fill: $secondary-color24 !important;
  }
}

.banner-mask-container {
  position: relative;
}

.banner-mask-container__title {
  font-family: "HelveticaNow-Regular";
  color: $third-color24;
  mix-blend-mode: difference;
  font-size: 2.5rem;
  position: absolute;
  margin-top: 8rem;
  margin-left: 2.5rem;
}

.banner-mask-container__svg {
  width: 100%;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
  }
}
.banner-mask-container__img {
  object-fit: cover;
  width: inherit;
}

.primary-btn {
  color: $secondary-color24;
}

.secondary-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: rgb(214, 214, 214, 0.8);
  height: 2.5rem;
  font-family: "HelveticaNow-Medium";
  font-size: 0.875rem;
  padding: 0 1.5rem;
  border-radius: 100rem;
  cursor: pointer;
  transition: ease background-color 250ms;
  border: 0;
  outline: 0;
  backdrop-filter: blur(8px);

  & span {
    color: $third-color24;
    mix-blend-mode: difference;
  }

  &:hover {
    background-color: rgb(242, 242, 242, 0.8);
    color: #111111;
  }

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
}

.content-social {
  display: flex;
  gap: 1.5rem;
}

.social-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $primary-color24;
  font-family: "HelveticaNow-Regular";
  font-size: 0.875rem;
  cursor: pointer;
  transition: ease-in background-color 250ms;

  &:hover {
    color: #444444;
  }

  &:hover .btn-icon--secondary {
    color: #444444;
    -webkit-animation: spin 900ms ease-in infinite;
    -moz-animation: spin 900ms ease-in infinite;
    animation: spin 900ms ease-in infinite;
  }

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
}

.card-data__icon {
  display: flex;
  align-items: baseline;
  font-size: 1.5rem;
  width: 2rem;
  color: $primary-color24;
}

.btn-icon {
  display: flex;
  align-items: center;
  font-size: 1rem;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  background-color: $secondary-color24;
  color: $primary-color24;
  border-radius: 100rem;

  &--secondary {
    width: auto !important;
    height: auto !important;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.text-comming-soon {
  font-family: "HelveticaNow-Regular";
  color: $primary-color24;
  font-size: 1rem;
  text-align: right;

  & span {
    font-family: "HelveticaNow-Bold";
  }
}

.zeroo-stand-video {
  height: 40rem;
  border-radius: 1.5rem;
  width: fit-content;
}

.selected-works {
  width: 100%;
  margin-top: 7rem;
}

.selected-works__header {
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;

  .selected-works__title {
    font-family: "HelveticaNow-Regular";
    color: $third-color24;
    mix-blend-mode: difference;
    font-size: 2.5rem;
  }

  .selected-works__date {
    font-family: "HelveticaNow-Regular";
    color: $third-color24;
    mix-blend-mode: difference;
    font-size: 1rem;
  }
}

.projects-frame {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 0 1rem;

  & .row {
    display: flex;
    width: auto;
    flex-direction: row;
    gap: 2.5rem;
  }
}

.tags-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: -webkit-fill-available;
  gap: 0.25rem;
  position: absolute;
  z-index: 1;
  padding: 0.5rem;
  mix-blend-mode: difference;

  &--project {
    position: relative;
    padding: 0;
  }
}

.tag {
  display: flex;
  align-items: center;
  backdrop-filter: blur(8px);
  background-color: rgb(17, 17, 17, 1);
  color: $third-color24;
  height: 1.625rem;
  font-family: "PPFraktionMono-Regular";
  font-size: 0.75rem;
  letter-spacing: 0.09375rem;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
}

.project-card {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  position: relative;

  &__content-container {
    height: 21rem;
    display: flex;
    overflow: hidden;
    position: relative;
    border-radius: 0.75rem;
    cursor: pointer;

    &--stand {
      align-items: flex-start;
    }
  }

  &__image {
    object-fit: cover;
    background-color: #ffffff;
    height: auto;
    position: relative;
    width: 100%;
    transition: transform 0.3s ease-in;
  }

  .project-card__content-container:hover .project-card__image {
    transform: scale(1.5); /* Scale the image slightly on hover */
  }
  .project-card__content-container:hover .project-card__video {
    transform: scale(1.5); /* Scale the image slightly on hover */
  }

  &__video {
    object-fit: contain;
    background-color: #ffffff;
    height: auto;
    position: relative;
    width: 100%;
    transition: transform 0.3s ease-in;
  }

  &__title {
    font-family: "HelveticaNow-Regular";
    color: $primary-color24;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.project-page {
  display: block;
  overflow: hidden;
  max-width: 1440px;
  margin: 0 auto;

  &__content {
    margin-top: 6.5rem;
  }

  &__banner {
    width: 100%;
  }
}

.new-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 16rem 0 1rem 0;
  font-family: "HelveticaNow-Regular";
  color: $primary-color24;
  font-size: 0.75rem;
}

.header {
  padding-top: 1.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__title {
    font-family: "HelveticaNow-Medium";
    color: $primary-color;
    font-size: 2.5rem;
    width: 55%;
    line-height: 130%;
  }

  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__teams {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  &__role {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    span {
      font-family: "HelveticaNow-Regular";
      color: rgb(17, 17, 17, 0.5);
      font-size: 1rem;
    }
  }
}

.avatar {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  cursor: pointer;

  &__image-container {
    height: 2rem;
    width: 2rem;
    display: flex;
    overflow: hidden;
    position: relative;
    border-radius: 1000px;
  }

  &__image {
    object-fit: cover;
    background-color: #ffffff;
    height: auto;
    position: relative;
    width: 100%;
    transition: transform 0.3s ease-in;
  }

  &__title {
    font-family: "HelveticaNow-Bold";
    color: $primary-color24;
    font-size: 0.875rem;
  }
}

.project-content {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 8.5rem;

  &__intro {
    font-family: "HelveticaNow-Regular";
    color: $primary-color24;
    font-size: 1.5rem;
    padding: 0 10%;
  }
}

.border {
  background: linear-gradient(
    90deg,
    rgba(232, 232, 232, 0) 0%,
    #e8e8e8 52.5%,
    rgba(232, 232, 232, 0) 100%
  );
  width: 100%;
  height: 1px;
}

.project-section {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding: 0 10%;
}

.project-section__intro {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.project-section__title {
  font-family: "HelveticaNow-Regular";
  color: $primary-color24;
  font-size: 2rem;
}

.project-section__subtitle {
  font-family: "HelveticaNow-Regular";
  color: $primary-color24;
  font-size: 1.5rem;
}

.project-section__paragraph {
  font-family: "HelveticaNow-Regular";
  color: $primary-color24;
  font-size: 1rem;
}

.project-section__gray-text {
  font-family: "HelveticaNow-Regular";
  color: rgb(17, 17, 17, 0.5);
  font-size: 1rem;
}

.box-box {
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
  display: flex;
  flex-direction: row;
}

.box-box__column {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
}

.discovery-image {
  padding-left: 1rem;
  padding-right: 1rem;
}

.card-image__container {
  height: 40rem;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 0.75rem;
  cursor: pointer;
}

.card-image {
  object-fit: cover;
  background-color: #ffffff;
  height: auto;
  position: relative;
  width: 100%;
  transition: transform 0.3s ease-in;
}

.card-data {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.card-data__title {
  font-family: "PPFraktionMono-Regular";
  color: rgb(17, 17, 17, 0.5);
  font-size: 0.75rem;
  letter-spacing: 0.1rem;
}

.card-data__number {
  font-family: "HelveticaNow-Regular";
  color: $primary-color24;
  font-size: 2.5rem;
}

.thesis-card__video {
  object-fit: contain;
  background-color: #ffffff;
  height: auto;
  position: relative;
  width: 100%;
}

.mobile-content {
  display: none;
}

@media (max-width: 700px) {
  .landing-page {
    display: none;
  }

  .project-page {
    display: none;
  }

  .new-header {
    display: none;
  }

  .paper-btn {
    display: none !important;
  }

  .mobile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    z-index: 999;
    gap: 2.5rem;

    .loader {
      text-align: center;
    }
  }
}
