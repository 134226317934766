.box {
  padding-bottom: 2rem;
  border-bottom: 1px solid $secondary-color;
}

.projects-intro {
  display: flex;
  position: relative;

  @include lg {
    display: block;
    position: relative;
  }

  &-left {
    position: absolute;

    @include lg {
      position: relative;
    }
  }

  &-title {
    font-family: "Barracuda-Bold";
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: 0.05em;
    color: $secondary-color;
  }

  &-type {
    margin-top: 0.5rem;
    font-family: "NeueMontreal-Italic";
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: 0.05em;
    color: $secondary-color;
  }

  & span {
    font-family: "NeueMontreal-Regular";
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: 0.05em;
    color: $third-color;
    position: absolute;
  }

  &-data {
    font-family: "NeueMontreal-Regular";
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: 0.05em;
    color: $secondary-color;
    margin-left: 4rem;
    width: 9rem;

    &.description {
      letter-spacing: 0.005em;
      width: 19rem;
      margin-left: 19rem;
      margin-top: 0;

      @include lg {
        margin-left: 0;
        margin-top: 2rem;
        width: 100%;
      }
    }
  }

  &-team {
    margin-top: 1rem;
  }

  &-date {
    position: absolute;
    bottom: 0;
    margin-top: 0;

    @include lg {
      margin-top: 1rem;
      position: relative;
    }
  }
}
