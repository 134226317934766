.paulomartins {
  padding: 2rem 3rem 0;

  @include xl {
    padding: 2rem 1rem 0;
  }

  @include sm {
    padding-bottom: 15rem;
  }

  section {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: -1;
  }
}
